/* Authorization Page */

.stripe-authorization-container {
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  border-radius: 5px;
  width: 350px;
  margin: 100px auto;
}

.stripe-authorization-container .main-logo {
  width: 150px;
}
/* Connect Buttons! */

.stripe-connect {
  background: #53bb1a;
  display: inline-block;
  height: 38px;
  text-decoration: none;
  width: 150px;
  max-width: 150px;
  min-width: 150px;
  padding-right: 15px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  -webkit-font-smoothing: antialiased;
}
.stripe-connect.inactive-other {
  background: #a0c68c;
}
.stripe-connect.inactive-other:hover {
  background: #cb8b8b;
}
.stripe-connect span {
  color: #ffffff;
  display: block;
  font-family: sohne-var, 'Helvetica Neue', Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  padding: 11px 0px 0px 24px;
  position: relative;
  text-align: left;
}

.stripe-connect:hover {
  background: #83cd5c;
  /* background: #7a73ff; */
}

.stripe-connect.slate {
  background: #0a2540;
}

.stripe-connect.slate:hover {
  background: #425466;
}

.stripe-connect.white {
  /* background: #ffffff; */
  width: 180px;
  max-width: 180px;
  min-width: 180px;
  padding-right: 0px;
}
.register-box .stripe-connect.white span {
  color: black !important;
}
.stripe-connect.white span {
  color: #0a2540;
}

.stripe-connect.white:hover {
  /* background: #c6debb; */
}

.stripe-connect span::after {
  background-repeat: no-repeat;
  background-size: 49.58px;
  content: '';
  height: 20px;
  left: 62%;
  position: absolute;
  top: 28.95%;
  width: 49.58px;
}

/* Logos */
.stripe-connect span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.stripe-connect.white span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%230A2540;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

stripe-connect.inactive-other {
  background: #a0c68c;
}

.stripe-connect.inactive-other:hover {
  background: #cb8b8b;
}

.stripe-connect.active-other {
  background: #a0c68c;
}

.stripe-connect.active-other:hover {
  background: #53bb1a;
}

.stripe-connect span {
  color: #ffffff;
  display: block;
  font-family: sohne-var, 'Helvetica Neue', Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  padding: 11px 0px 0px 24px;
  position: relative;
  text-align: left;
}

.stripe-connect:hover {
  background: #83cd5c;
  /* background: #7a73ff; */
}

.stripe-connect.slate {
  background: #0a2540;
}

.stripe-connect.slate:hover {
  background: #425466;
}

.stripe-connect.white {
  background: #c6debb;
  width: 180px;
  max-width: 180px;
  min-width: 180px;
  padding-right: 0px;
}
.register-box .stripe-connect.white span {
  color: black !important;
}
.stripe-connect.white span {
  color: #0a2540;
}

.stripe-connect.white:hover {
  background: #83cd5c;
}

.stripe-connect span::after {
  background-repeat: no-repeat;
  background-size: 49.58px;
  content: '';
  height: 20px;
  left: 62%;
  position: absolute;
  top: 28.95%;
  width: 49.58px;
}

/* Logos */
.stripe-connect span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.stripe-connect.white span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%230A2540;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

#message-input-container .stripe-connect.white {
  padding-right: 0px;
}

/* Make Payment Forms */

#message-input-container .ant-modal {
  top: 20%;
}

/* Payment Modal */

.ant-modal.payment-modal {
  top: 25vh;
  text-align: center;
}

.payment-modal .ant-modal-header {
  background-color: #2e8631;
  color: white !important;
}
.payment-modal .ant-modal-header .ant-modal-title {
  color: white !important;
}

.redirect-modal .ant-modal-body {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.payment-product-skeleton {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.payment-modal-image {
  max-width: 200px;
  max-height: 200px;
}

.payment-product-row {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.stripe-container {
  margin-top: 35px;
}

.success-payment-check {
  position: absolute;
  z-index: 2;
}

/* checkout */

.checkout-component {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 40px;
}

.checkout-reached-page-in-error {
  width: 340px;
  font-size: 20px;
  height: 60vh;
  display: flex;
  align-items: center;
}

.checkout-back-button-container {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}

.checkout-back-button {
  margin-left: 16px;
}

.select-gear-button {
  margin-right: 5px;
  border: none;
  background-color: #a3a3a3;
  color: white;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 5px;
  width: 100px;
  height: 40px;
}

.action {
  background-color: greenyellow;
  color: #444;
  font-size: 16px;
  font-weight: 400;
  min-width: 150px;
  box-shadow: 0 0 2px 2px olivedrab;
  box-sizing: border-box;
  margin-right: 12px;
}

.btn:disabled,
.btn.disabled {
  border-color: #a0a0a0;
  background-color: #a0a0a0;
  box-shadow: none;
}
.btn:disabled:hover,
.btn:disabled:focus,
.btn.disabled:hover,
.btn.disabled:focus {
  color: #fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
}

.select-gear-button:hover {
  background-color: #f03c13;
}
.action:hover {
  background-color: #45ff2f;
  box-shadow: 0 0 3px 3px rgb(32, 158, 1);
}

.select-gear-button-container {
  margin-top: 10px;
}

.payment-component {
  width: 340px;
}

.payment-title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 5px;
}

.payment-hr-container {
  display: flex;
  justify-content: center;
}

.payment-hr {
  margin: 0;
  width: 300px !important;
  height: 1px;
  background-color: lightgray;
  border: none;
}

.payment-details-component {
  font-size: 16px;
}

.payment-details-container {
  display: flex;
  margin-bottom: 20px;
}

.payment-details-item-and-price {
  display: flex;
  padding: 10px 0;
}

.payment-details-item-list {
  margin-top: 10px;
}

.payment-details-item-quantity {
  width: 40px;
}

.payment-details-item {
  width: 280px;
  font-weight: 500;
}

.payment-details-item-price {
  width: 60px;
  text-align: right;
}

.payment-details-labels {
  margin-right: 20px;
  width: 260px;
}

.payment-details-labels > div {
  padding: 10px 0;
}

.payment-details-values {
  width: 80px;
}

.payment-details-values > div {
  text-align: right;
  padding: 10px 0;
}

.payment-card-element-container {
  margin-bottom: 40px;
}

.payment-form-button-container {
  margin-bottom: 40px;
}

.payment-error-message {
  color: red;
}

.payment-details-item-title {
  margin-bottom: 20px;
  font-size: 16px;
}

.payment-success-component {
  margin-top: 100px;
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-success-image-container {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.payment-success-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.payment-success-message {
  font-size: 20px;
}

.payment-back-button-container {
  margin-top: 5px !important;
  margin-bottom: 8vh !important;
}

.payment-minimum-transaction-message {
  margin-bottom: 30px;
}

.bold {
  font-weight: 600;
}

.rental-summary {
  font-size: 24px;
  padding: 12px 0;
}

.text-disabled {
  color: grey;
  opacity: 50%;
}

.checkout-product-details > div {
  padding: 4px 0;
}

/* rent multiple items changes */

.select-gear-component {
  display: flex;
  flex-direction: column;
  width: 340px;
  font-size: 16px;
}

.select-gear-item-list-component,
.select-gear-order-details-component {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-gear-hr {
  background-color: lightgray;
  border: none;
  height: 1px;
  width: 300px;
  margin-bottom: 10px;
}

.select-gear-order-details-component {
  margin-bottom: 40px;
  margin-top: 10px;
}

.select-gear-item-list-title,
.select-gear-order-details-title {
  text-align: center;
  font-size: 22px;
}

.select-gear-item-list-item {
  height: 50px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 14px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.select-gear-item-list-item-title {
  margin-right: 10px;
}

.selected-item {
  border-color: yellowgreen;
  box-shadow: 1px 1px seagreen;
}

.select-gear-order-details-items-container {
  margin-bottom: 20px;
}

.select-gear-order-details-item-image-container {
  height: 100px;
  width: 100px;
  margin-right: 30px;
}

.select-gear-order-details-item-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.select-gear-order-details-item {
  margin-bottom: 20px;
}

.select-gear-order-details-image-remove-quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.select-gear-order-details-image-and-remove {
  display: flex;
  align-items: center;
}

.select-gear-order-details-item-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.select-gear-order-details-item-quantity-label {
  margin-right: 10px;
}

.select-gear-order-details-item-quantity > .ant-select-selector {
  outline: none !important;
  border-color: lightgray !important;
  box-shadow: none !important;
  border-radius: 5px !important;
}

.select-gear-order-details-item-title {
  margin-right: 15px;
}

.select-gear-order-details-item-info {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.select-gear-order-details-duration-select-container {
  margin-bottom: 20px;
}

.select-gear-order-details-duration-select {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.select-gear-order-details-duration-select > .ant-select-selector {
  outline: none !important;
  border-color: lightgray !important;
  box-shadow: none !important;
  border-radius: 5px !important;
}

.select-gear-order-details-duration-select > .ant-select-selector {
  border-radius: 5px !important;
}

.select-gear-order-details-fees,
.select-gear-order-details-total,
.select-gear-order-details-subtotal {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.select-gear-verify-details-message {
  margin-bottom: 20px;
}

.select-gear-promo-code-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 200px;
}

.select-gear-promo-code-container .MuiFormHelperText-root {
  color: red;
}

#promo-code-submit-button {
  margin-top: 10px;
  width: 80px;
}

.select-gear-minimum-transaction-message {
  margin-bottom: 10px;
}

/* remove arrows on number inputs */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 420px) {
  .select-gear-item-list-item:hover {
    border-color: yellowgreen;
  }
}

@media only screen and (min-width: 600px) {
  .select-gear-component {
    width: 450px;
  }

  .payment-component {
    margin-top: 12vh;
  }

  .payment-title {
    font-size: 24px;
  }

  .payment-details-item-list,
  .payment-details-container {
    font-size: 18px;
  }
}

@media only screen and (min-width: 715px) {
  .select-gear-order-details-component {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1000px) {
  .select-gear-component {
    flex-direction: row;
    width: 900px;
    margin-top: 20px;
  }

  .select-gear-order-details-component {
    margin-top: 0;
    width: 490px;
  }

  .select-gear-item-list-component {
    margin-right: 50px;
    width: 360px !important;
  }
}

@media only screen and (min-width: 1300px) {
  .select-gear-component {
    width: 1100px;
  }

  .select-gear-order-details-component {
    width: 590px;
  }

  .select-gear-item-list-component {
    margin-right: 150px;
  }
}
