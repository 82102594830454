#navLogo {
  width: 200px;
  margin: 5px 0px;
  margin-left: 0%;
  cursor: pointer;
  display: flex;
  justify-content: left;
}
#navContainer {
  position: relative;
  top: 0;
  height: 67px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
  background-color: var(--white);
  z-index: 1;
  box-shadow: 0 0 3px 0px lightgray;
  padding: 0 30px 0 30px;
}
.navMenu {
  min-width: 288px;
}
.navMenu,
.navMenu a {
  color: lightgray !important;
  background: none !important;
}

#rightSideNavElements {
  display: flex;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-submenu-open:hover,
.ant-menu-submenu-title:hover,
#navMenu a:hover {
  color: var(--g2gMedium) !important;
  border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: var(--g2gLight) !important;
}
.ant-menu-horizontal {
  border-bottom: none !important;
}
.profileIcon {
  width: 35px;
  cursor: pointer;
  transition: 0.5s ease;
  border-radius: 50%;
  background-color: lightgray;
  margin-left: 20px;
}
.profileIcon:hover {
  background-color: lightgray;
  border-radius: 50%;
  padding: 5px !important;
  transition: 0.5s ease;
}
.ant-dropdown-menu-item:hover {
  background-color: var(--blue3) !important;
}

.unread-messages-menu-item {
  background-color: #b8dd97 !important;
}
.message-count-nav {
  position: absolute;
  right: 0px;
  top: -7%;
  color: #e20c0c;
  font-size: 1rem;
  font-family: Impact, fantasy;
}

.productNavbar {
  color: white;
  position: relative;
  left: 5%;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px 0px;
  width: 100%;
  margin: auto 4%;
  max-width: 360px;
  min-width: 250px;
}
.productNavbar .ant-input-group {
  width: 125%;
}
.productNavbar p {
  margin-top: -17px;
}
.productNavbar > * {
  margin-right: 10px;
}
.searchProductName {
  width: 60% !important;
}

.gear-bag-image-navbar {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.small-nav {
  display: none;
}

.small-nav > * {
  color: var(--g2gDark);
}

.small-nav-item {
  font-size: 14px !important;
}

#menu-text {
  font-size: large;
  /* font-weight: bold; */
  color: var(--black) !important;
  font-family: 'Sanchez', serif !important;
}

.medium-menu-item {
  display: none;
}


@media (max-width: 600px) {
  #navContainer {
    padding-right: 30px !important;
  }

  .navbar-lend-link {
    margin-right: 10px !important;
  }
}

@media (max-width: 800px) {
  #smallLogo {
    display: inline !important;
    width: 75px;
  }

  #navLogo {
    display: none !important;
  }
}
