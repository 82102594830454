:root {
  --input-border: solid 1px rgba(233, 230, 230, 0.413);
  --input-bc: rgba(240, 240, 240, 0.413);
}

#creationForm {
  position: relative !important;
  border-radius: 5px;
  min-width: 360px;
  max-width: 500px;
  min-height: 30vh;
  margin: auto;
  padding: 30px;
  text-align: center;
}
#creationForm h1 {
  font-size: 30px;
}
#creationForm .form {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
#creationForm fieldset {
  padding: 10px;
  border: none;
}

.register-box label {
  color: lightgray;
}
/* .register-box fieldset{
    text-align: right;
} */

.highlight-red {
  border: 1px solid red !important;
}

#lendingForm {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  /* background-color: black; */
  width: 90vw;
  margin: 30px auto;
}

#lendingForm fieldset {
  width: 60vw;
  max-width: 700px;
}

.lend-field-header {
  margin-top: 45px;
}

#lendingForm .ant-input {
  border: var(--input-border);
  background-color: var(--input-bc);
  height: 40px;
}
#lendingForm .ant-input::placeholder {
  color: rgb(189, 181, 181);
  font-family: var(--subheadline);
}
#description {
  border: var(--input-border);
  background-color: var(--input-bc);
  height: 120px !important;
}
#rental-instructions .ant-input {
  border: var(--input-border);
  background-color: var(--input-bc);
  height: 100px;
}
#image-parent .ant-input {
  border: none;
  background-color: rgba(255, 255, 255, 0);
  height: 50px;
  margin-top: 15px;
}

.notARobot {
  display: flex;
  margin-top: 50px;
  margin-bottom: 40px;
}

.dropdownTitle {
  margin-right: 20px;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
}

.dropdown {
  border: solid 1px rgba(233, 230, 230, 0.413);
  /* box-shadow: inset rgba(197, 192, 192, 0.557) -7px 2px 5px 0.1rem; */
  background-color: var(--input-bc);
  height: 3vh;
  width: 200px;
}

.selectedImages {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: auto;
}

.selectedImages div {
  position: relative;
}

.selectedImages div img {
  /* position: absolute;
    top:0;
    left: 0; */
  width: auto;
  max-height: 200px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.selectedImages div .closeIcon {
  position: absolute;
  top: 4px;
  right: 10px;
  font-size: 1em;
  color: white;
}

.selectedImages div .closeIcon:hover {
  color: var(--g2gDark);
}

.edit-label {
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: 16px;
}

.upload-image-btn {
  margin: auto;
  border: 0.5px solid #444;
  background-color: greenyellow;
  /* width: 200px; */
  padding: 12px;
  border-radius: 4px;
}

.empty-images,
.upload-image-btn {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.upload-image-btn:hover {
  background-color: rgb(95, 148, 21);
}

@media only screen and (max-width: 600px) {
  #creationForm {
    min-width: fit-content;
    padding: unset;
  }
}
