:root {
  --black: rgba(0, 0, 0);
  --blue: #1890ff;
  --blue2: #086fce;
  --color1: rgba(64, 71, 65);
  --color2: rgba(109, 137, 111);
  --color3: rgba(173, 133, 69);
  --gray: rgba(234, 234, 234);
  --gray2: rgb(223, 208, 208);
  --white: rgba(255, 255, 255);
  --headline: 'Poppins', sans-serif;
  --subheadline: 'Sanchez', serif;
  --body-font: 'Montserrat', sans-serif;
}

/* #animationContainer{
  margin: 90px 50px 40px 50px;
  min-height: 340px;
  min-width: 350px;
  overflow:hidden;
  position:relative;
  background-size:100% 100%;
  transition: 0.2s ease;
  border-radius: 1000px;
  padding: 40px 50px;
  box-shadow: -8px -8px 20px 0px rgb(208, 208, 208),8px 8px 20px 0px #727272;
  /* background-color: #d3f1c2; */
/* background-image: linear-gradient(to bottom right, #f1feea, #86b36b);
  width: 40vw;
  max-width: 500px;
  max-height: 480px;
  height: 38vw;
} */
.flexColumn {
  display: flex;
  flex-direction: column;
}

.homeSearchTitle {
  font-size: 4em;
  font-weight: 700;
  color: var(--white);
  text-shadow: 5px 5px 5px var(--color2);
}

.searchBarContainer {
  max-width: 700px;
}

#homeContainer {
  position: relative;
  text-align: center;
  margin: 0 auto 0 auto;
  width: 100%;
  min-height: 40vh;
  height: 90vh;
  /* background-image: url('../../images/landingImages/tent-desert.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#home-message-container {
  width: 100%;
  min-width: 350px;
  padding: 34px;
  text-align: left;
  /* margin: 30px; */
}

#home-message-container > * {
  margin: 20px;
  color: white !important;
}

.lend-button {
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  right: 20% !important;
  left: 80% !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  background-color: #008cba;
  color: white;
  font-size: 25px;
  transition-duration: 0.4s;
  padding: 12px 28px;
  border-radius: 10%;
}

.lend-button:hover {
  background-color: white;
  color: black;
}

.logged-in-box {
  min-width: 350px;
  padding: 20px;
  position: absolute;
  top: 45%;
  left: 50%;
  max-width: 850px;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  box-shadow: 0 15px 25px var(--bg-color);
  border-radius: 10px;
}

.logged-in-box h1 {
  font-size: 32px !important;
  color: rgb(216, 216, 216);
  margin-top: 20px;
}

.login-box .main-logo {
  width: 210px;
}

.main-logo {
  max-width: 375px;
  width: 100%;
}

.rent-button {
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  right: 80% !important;
  left: 20% !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  background-color: #008cba;
  color: white;
  font-size: 25px;
  transition-duration: 0.4s;
  padding: 12px 28px;
  border-radius: 10%;
}

.rent-button:hover {
  background-color: white;
  color: black;
}

.scroll-down-arrow {
  width: 20%;
  min-width: 50px;
  max-width: 150px;
  position: absolute;
  bottom: 20px;
  height: 80px;
}
.scroll-down-arrow path {
  fill: rgba(146, 181, 126, 0.78);
  stroke: lime;
}

/* LOGIN COMPONENT */

:root {
  --primary-color: white;
  --secondary-color: #38913b;
  --bg-color: rgba(0, 0, 0, 0.8);
}

.btn {
  width: 130px;
  height: 50px;
  background: none;
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
  color: var(--primary-color);
  padding: 5px;
  font-size: 18px;
  font-family: var(--subheadline);
  cursor: pointer;
  /* margin: 12px 0; */
  color: var(--primary-color);
  background-color: var(--black);
  transition: 0.2s ease;
}

.btn:hover {
  background-color: #2e8631;
}
.btn:active {
  transform: translateY(1px);
}

.home-message-login-box {
  min-width: 350px;
  padding: 20px;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  box-shadow: 0 15px 25px var(--bg-color);
  border-radius: 10px;
  width: 75%;
  max-height: 490px;
}

.home-message-login-box .login-box {
  transform: none;
  background: transparent;
  box-shadow: none;
  position: relative;
  margin: 20px;
  width: 80%;
  top: 0;
}

.login-bool {
  display: none;
}

.login-box {
  width: 320px;
  padding: 20px;
  position: absolute;
  top: 45%;
  right: 0%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  box-shadow: 0 15px 25px var(--bg-color);
  border-radius: 10px;
}

.login-box h3 {
  font-size: 26px;
  color: var(--primary-color);
  margin-bottom: 0px;
  padding: 13px 0;
  text-align: left;
}
.login-box p {
  color: var(--g2gLight);
}
.login-box .register-link:hover {
  color: var(--g2gMedium) !important;
  text-decoration: underline;
  cursor: pointer;
}

/* Recent Gear Scroller */

.browse-title {
  /* margin-top: 50px; */
  font-size: x-large;
  margin-bottom: 0;
  background-color: rgb(242, 242, 242);
  text-align: left;
  /* box-shadow: 0 0 10px 3px #9b9b9b; */
  width: 100%;
  height: 55px;
  padding: 10px;
  width: 100%;
}

.go-to-rent-icon {
  width: 150px;
  height: 150px;
  background-color: white;
  border-radius: 50%;
  padding: 40px;
  cursor: pointer;
  margin: 0 40px;
  transition: 0.2s ease;
  border: 1px solid rgb(174, 174, 174);
}
.go-to-rent-icon:hover {
  background-color: lightgray;
  transition: 0.2s ease;
  box-shadow: inset 2px 2px 8px 2px;
}
.go-to-rent-icon:active {
  transform: translateY(3px);
  transition: 0.2s ease;
  box-shadow: inset 3px 3px 4px 3px;
}

#icon-text {
  justify-content: center;
}

.img-text-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding: 5px 20px;
  border-radius: 30%;
  transition: 0.2s ease;
  width: 190px;
  height: 180px;
}

.img-text-container:hover {
  box-shadow: inset 2px 2px 2px #999, inset -1px -1px #999;
}

.img-text-container:active {
  box-shadow: inset 2px 2px 6px 1px rgb(80, 80, 80), inset -2px -2px #999;
  transform: translateY(2px);
  background-color: #b4c993;
}

.icon-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  height: 100px;
  width: 100px;
}

.category-icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.outdoors-icon {
  height: 100px;
  width: 100px;
  margin: 15px;
  margin-bottom: 5px !important;
}

.outdoor-text {
  padding-left: 20px;
}

.logged-in-box.home-message,
.login-box.home-message {
  display: flex;
  width: 70% !important;
  align-content: center;
  align-items: center;
  flex-direction: row-reverse;
}
.logged-in-box.home-message {
  max-height: 465px;
  justify-content: center;
}
.logo-container {
  width: 60%;
  margin: 15px;
}

.recent-gear-container {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  margin: 0 0 30px 0;
  padding: 20px;
  background-color: rgb(242, 242, 242);
  /* box-shadow: 0 3px 10px 0px #5b5b5b;; */
  align-items: center;
}

.recent-gear-container .recent-cards {
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  margin: 0 100px 0 100px;
  align-items: center;
}

.recent-title {
  margin-top: 50px;
  margin-bottom: 0;
  background-color: lightgray;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 10px 3px #9b9b9b;
}

.recent-gear-container .productCard {
  min-width: 250px !important;
  margin: 15px 10px !important;
  border: none;
  transition: 0.3s ease;
}
/* .recent-gear-container .productCard:hover{
  background-color: rgb(242, 240, 240) !important;
  transition: 0.3s ease;
} */

.recent-gear-container .productCard .ant-card-actions li {
  background-color: #f7f7f7;
  border-top: 1px solid #dadada !important;
  transition: 0.3s ease;
}
/* .recent-gear-container .productCard .ant-card-actions li:hover{
  background-color: rgb(242, 240, 240) !important;

} */

.search-icons-container {
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.search-icons-container-parent {
  padding: 20px;
  background-color: var(--white);
  box-shadow: 0 3px 10px 0px #5b5b5b;
  width: 100%;
}
.small-message-logo {
  display: none;
}

.textbox {
  width: 100%;
  overflow: hidden;
  font-size: 20px;
  padding: 8px 0;
  margin: 8px 0 30px 0;
  border-bottom: 2px solid var(--secondary-color);
}

.textbox i {
  width: 26px;
  float: left;
  text-align: center;
  color: var(--primary-color);
}

.textbox input {
  border: none;
  outline: none;
  background: none;
  font-size: 18px;
  width: 80%;
  float: left;
  margin: 0 10px;
  color: var(--primary-color);
}

.other-member-card {
  height: 440px !important;
}

@media only screen and (max-width: 600px) {
  #homeContainer {
    height: 60vh;
  }
}
