#faqs-container h1 {
  text-align: center;
  background-color: var(--black);
  color: #dbd7cf;
  padding-bottom: 10px;
  font-family: var(--headline) !important;
}
#lenderFaqs {
  width: 60vw;
  margin-top: 40px;
}
#lenderFaqs-container {
  display: flex;
  justify-content: center;
}
#lenderFaqs h1 {
  justify-content: left;
  text-align: left;
  /* background-color: var(--black); */
  color: var(--color1);
  padding-bottom: 10px;
  font-family: var(--headline) !important;
}
#lenderFaqs #question {
  font-family: var(--body-font);
}
#lenderFaqs .answer p {
  font-family: var(--subheadline);
}
.summary {
  font-size: 1.25rem;
  width: 100%;
  font-weight: 600;
  background-color: #fff;
  color: #333;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  border-radius: 0.25rem;
  text-align: left;
  cursor: pointer;
  position: relative;
}

#question {
  /* font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    font-size: 150% !important; */
}

.answer {
  /* font-weight: bold !important; */
  margin-left: 30px !important;
  /* font-size: medium !important; */
  color: black !important;
  font-family: 'Times New Roman', Times, serif !important;
  font-size: 90% !important;
}

#faqs-container {
  width: 80%;
  max-width: 720px;
  min-width: 370px;
  padding: 40px;
  margin: 40px auto;
  border-radius: 15px;
  box-shadow: 4px 4px 6px 6px lightgray;
  background-color: white;
}

#whats-next {
  text-align: center !important;
  /* font-weight: 500 !important; */
  /* font-size: 190% !important; */
  text-decoration: underline !important;
}
