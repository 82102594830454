/* Temporary mobile responsiveness settings */
@media (max-width: 715px) {
  /* Category styling for top menu */

  .footer-text {
    flex-direction: column;
  }

  .categoryMenu {
    position: sticky !important;
    top: 0;
    min-width: 162px;
    max-height: 700px;
    overflow: auto;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgb(232, 232, 232);
    z-index: 1000;
  }

  .categoryMenu .individual-category {
    width: 80% !important;
    overflow: visible !important;
    background-color: #e6e6e6;
    margin: 0 10px !important;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    max-width: 180px;
    align-items: center;
  }

  .categoryMenu .individual-category:hover {
    background-color: #1890ff;
  }

  .categoryMenu-container {
    position: absolute !important;
    min-width: 162px;
    height: 87%;
    max-height: 700px;
    overflow: auto;
    box-shadow: 2px 2px 2px 3px lightgray !important;
    max-width: 300px;
    width: 20vw;
    background-color: rgb(187, 206, 190) !important;
  }

  .productContainer {
    margin: auto;
    justify-content: center;
  }
  .rent-page-faqs-container {
    margin: auto;
    justify-content: center;
  }
  .rent-page-faqs {
    width: 90vw;
  }

  /* /lend Sell.css */
  .lend-field-header {
    margin-top: 15px;
  }
  #lendingForm fieldset {
    width: 90vw;
  }
  #lendingForm {
    justify-content: center;
  }
  /* end Sell.css */
  .small-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    box-shadow: 0 0 3px 1px lightgray;
    cursor: pointer;
    font-size: 16px;
  }
  .category-icon {
    height: 80px;
    width: 80px;
  }
  #navContainer {
    padding: 0 15px 0 0px;
  }
  .small-nav a {
    width: 100%;
    text-align: center;
  }
  .small-dropdown {
    width: 100vw !important;
  }
  .small-dropdown .ant-dropdown-menu-item:hover {
    background-color: var(--g2gLight2) !important;
  }
  .navMenu {
    display: none;
  }
  #navLogo {
    margin: 5px 5px 5px -10%;
    position: relative;
    z-index: 1;
    width: 30%;
  }
  .productNavbar {
    width: 55%;
    position: absolute;
    min-width: 100px;
    margin-left: 38px;
    top: 7px;
  }
  .productNavbar .ant-input-group {
    min-width: 100px;
  }
  .productNavbar > * {
    margin-right: -30%;
  }
  .small-nav-item:hover {
    color: var(--g2gDark) !important;
    background-color: var(--g2gLight) !important;
  }
  .profileIcon {
    z-index: 1;
  }

  .categoryMenu .ant-menu-item {
    margin: 0 5px;
  }
  .productContainer {
    left: 0 !important;
    width: 100% !important;
  }
  .home-buttons {
    display: none;
  }
  .productCard {
    width: 175px !important;
    margin: 3px 1px 2px 1px !important;
    box-shadow: none !important;
    align-items: space-evenly !important;
  }
  .productCard .ant-card-body {
    justify-content: center !important;
  }
  .productCard.landscape .ant-card-body {
    margin-top: 10%;
    height: 100px !important;
  }

  .productCard.landscape {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .recent-gear-container .productCard {
    margin: 8px !important;
  }
  .productCard .ant-card-body {
    padding: 5px;
  }
  .productCard .ant-card-body ul {
    padding: 2px;
  }
  #home-message-container {
    text-align: center;
  }
  .login-box {
    left: 50%;
  }
  .login-box-main.login-box {
    top: 35%;
  }
  .logged-in-box {
    left: 50%;
  }

  .img-text-container {
    margin: 2px;
  }
  .search-icons-container {
    width: 100%;
  }
  .search-icons-container-parent {
    padding: 0;
  }
  .search-icons-container {
    padding: 0;
  }
  .cartItemsContainer {
    width: 99%;
    padding: 30px 0;
  }
  .lenderGearContainer {
    padding: 30px 0;
  }
  .my-message {
    margin-left: 23%;
  }
}
@media (max-width: 855px) {
  .home-message-login-box .login-box {
    display: none;
  }
  .small-message-logo {
    display: block;
    width: 64%;
    margin: 0 auto 20px auto !important;
    max-width: 290px;
  }
  .login-bool {
    display: block;
  }
  #home-message-container > * {
    margin: 0;
  }
  .logo-container {
    display: none;
  }
}
@media (max-width: 1000px) {
  #homeContainer {
    flex-direction: column-reverse;
    position: relative;
    min-height: 30vh;
    justify-content: left;
    align-items: center;
    padding-top: 50px;
    max-height: 30%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navMenu {
    min-width: 150px !important;
  }
  .scroll-down-arrow {
    bottom: 50px;
  }
  #animationContainer {
    margin: 30px !important;
  }
  /* .productNavbar{
      left: 0 !important;
    } */
  .medium-menu-item-show {
    display: block;
  }
  .medium-menu-item-hidden {
    display: none !important;
  }

  .channels-slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }

  .channels-slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }

  .showing-on-mobile {
    display: block !important;
  }
  /* #channels-mg-container {
    position: sticky;
    top: 0;
    min-width: 162px;
    height: fit-content;
    max-height: 700px;
    overflow: auto;
    width: 100%;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    z-index: 1000;
    flex-direction: row;
    overflow-y: hidden;
  } */

  /* #channels-mg-container > a {
    text-align: center;
    min-width: 250px;
    max-width: 270px;
    margin: auto;
    margin-left: 4px;
  }
  #channels-mg-container > a {
    min-width: 170px;
  }
  #channels-mg-container > h2 {
    padding: 7px;
    border-radius: 2px;
    font-size: 13px;
    margin-top: 7px;
    margin-right: 5px;
  } */
  .indv-channel-container {
    padding-left: 8px;
    height: 70px;
  }
  #main-messages-container {
    margin: 0px;
    width: 100vw;
    height: 100%;
  }
  #message-right-container {
    margin: 0px !important;
    height: 80vh;
  }
  #message-container {
    width: 100%;
    overflow-x: hidden;
  }
  #main-body-container {
    overflow-x: hidden;
  }
  #mainSearchBar::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: 'Montserrat', sans-serif;
    font-weight: lighter;
    font-size: 0.8rem;
  }
  #mainSearchBar::-moz-placeholder {
    /* Firefox 19+ */
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
  }
  #mainSearchBar:-ms-input-placeholder {
    /* IE 10+ */
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
  }
  #mainSearchBar:-moz-placeholder {
    /* Firefox 18- */
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
  }
}

/* Messaging On Mobile */
/* Media Queries */

@media (max-width: 450px) {
  .channels-slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }

  .channels-slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }

  #channels-mg-container {
    position: absolute;
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    width: 100%;
    height: 100vh;
    min-height: 100vw;
    background-color: rgb(196, 196, 196);
    border-right: 1px solid rgb(192, 184, 139);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    background-color: white;
    padding: 0px;
  }

  #channels-mg-container > a {
    margin: 2px auto !important;
    max-width: 100%;
    height: 80px;
  }

  #channels-mg-container > a {
    margin-top: 20px !important;
  }

  #channels-mg-container > h2 {
    margin-top: 15px;
    background-color: rgba(0, 0, 0, 0);
  }

  .indv-channel-container {
    padding: 40px 15px !important;
  }

  .indv-channel-container div {
    width: 100%;
    padding-right: 15px;
  }

  .indv-channel-container div div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #message-right-container {
    height: 80vh;
    min-height: 100vw;
    padding-top: 0;
  }

  .message-input-and-send {
    margin-bottom: 5px;
  }

  .hidden-on-mobile {
    display: none !important;
  }

  .showing-on-mobile {
    display: block !important;
  }
}
