.flex-row {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 24px;
}

.input-text,
.input-textarea {
  border: 0.5px solid grey;
  border-radius: 2px;
  padding: 8px;
}

.container .ant-btn-primary {
  background-color: #7ad669;
  color: black;
  padding: 8px 16px;
}

.container {
  padding: 8px;
  margin-bottom: 12px;
}

.review-center {
  margin: auto;
  width: 50%;
}

.hover-button:hover {
  background-color: rgb(0, 0, 0, 0.1);
  opacity: 80%;
  cursor: pointer;
}

.review-header {
  font-size: 24px;
  font-weight: 600;
  padding: 16px 0;
}

.review-image {
  width: 100px;
}
.review-image img {
  max-width: 100px;
}

.review-container {
  padding: 24px 0;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #444;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: -20px;
  left: 50%;
  max-width: 240px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#review-title {
  font-weight: 400;
  font-size: 24px;
}

#review-date {
  color: #444;
  font-weight: 600;
  letter-spacing: 0.4;
}

@media only screen and (max-width: 600px) {
  .review-center {
    width: 90%;
  }

  #review-title {
    font-size: 18px;
  }
}
