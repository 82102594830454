#instructions-container {
  width: 80%;
  max-width: 780px;
  min-width: 370px;
  padding: 40px;
  margin: 15vh auto 40px auto;
  border-radius: 15px;
  box-shadow: 4px 4px 6px 6px lightgray;
  background-color: white;
}

#instructions-container .ant-collapse-header {
  background-color: var(--g2gDark);
  color: antiquewhite;
}

.title {
  font-size: large;
}

#info-title {
  text-align: center;
}

#info-sub-title {
  font-family: var(--body-font);
  background-color: var(--black);
  color: var(--white);
  padding: 15px;
}

#instructions-container h3 {
  margin-bottom: 20px;
}

#question {
  font-size: large;
}
