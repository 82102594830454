.button--register {
  border: 2px solid #444;
  padding: 4px 12px;
  margin: 20px auto;
  border-radius: 4px;
  background: white;
  max-width: 250px;
  width: 80%;
  box-shadow: 0 0 1px 1px white;
  font-weight: 600;
  font-size: 16px;
  color: black;
  cursor: pointer;
}

.button--register-container {
  margin: 4px;
}
