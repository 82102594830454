html {
  overflow-x: hidden;
}

body {
  background-color: var(--white);
}

#mainBodyContainer {
  /* background-color: #e8e8e8; */
  background-color: var(--white);
  width: 100vw;
  height: 100%;
  min-height: 96vh;
  /* overflow: auto; */
  position: relative;
  padding: 1px;
  padding-bottom: 40px;
}
/* @media (prefers-color-scheme: dark) {
    body {
        background-color: rgb(54, 54, 54);
    }
    h1, h3 {
        color: antiquewhite !important;
    }
    
    #mainBodyContainer{
        background-color: rgb(56, 56, 56);
    }
} */
.hidden {
  display: none !important;
}

.showing-on-mobile {
  display: none;
}

.clickable {
  cursor: pointer;
}

#greyBackground {
  position: relative;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(35, 35, 35, 0.7);
}

.formExit {
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 2px;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
  transition: 0.2s ease;
}
.formExit:hover {
  color: white;
  border-radius: 2px;
  background-color: var(--bangazonBlack);
  transition: 0.2s ease;
}

button {
  cursor: pointer;
}

/* Animations */

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
