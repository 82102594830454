#instructions-container {
  width: 80%;
  max-width: 780px;
  min-width: 370px;
  padding: 40px;
  margin: 15vh auto 40px auto;
  border-radius: 15px;
  box-shadow: 4px 4px 6px 6px lightgray;
  background-color: white;
}

#instructions-container .ant-collapse-header {
  background-color: lightgray;
  color: black;
}

.title {
  font-size: large;
}

/* #info-title {
  text-align: center;
  text-decoration: underline;
} */

/* #info-sub-title {
    text-align: center;
} */

#instructions-container h3 {
  margin-bottom: 20px;
}

#question {
  font-size: large;
}

.contact-text {
  text-align: center;
  text-decoration: underline;
}

.plan-text {
  font-size: medium;
}
