.rentPageContainer {
  display: flex;
  width: 350px;
  background-color: var(--white);
}

.categoryMenu {
  position: fixed;
  top: 100px;
  left: 20px;
  width: 230px;
  overflow: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  z-index: 1000;
}

.categoryMenu .individual-category {
  width: 100% !important;
  overflow: visible !important;
  margin: 0 10px !important;
  border-radius: 4px;
  display: flex;
  justify-content: left;
  max-width: 190px;
  align-items: center;
}

.categoryMenu .individual-category:hover {
  color: #1890ff !important;
  background-color: #1890ff;
}

.categoryMenu-container {
  position: absolute !important;
  min-width: 162px;
  height: 87%;
  max-height: 700px;
  overflow: auto;
  box-shadow: 2px 2px 2px 3px lightgray !important;
  max-width: 300px;
  width: 20vw;
  background-color: rgb(187, 206, 190) !important;
}

.productContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 88vw;
  height: 100%;
  min-height: 70vh;
  justify-content: center;
  align-content: flex-start;
  margin: 0 auto 80px 215px;
}

.rent-page-faqs-container {
  width: 88vw;
  margin: 0 auto 80px 280px;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.rent-page-faqs {
  width: 66vw;
  max-width: 800px;
}

.productCard {
  background-color: var(--white) !important;
  margin: 30px 5px !important;
  width: 250px;
  height: 450px;
  /* box-shadow:inset -2px -2px 12px 5px #e1e1e1,inset 2px 2px 13px 0px #b3b3b3,-3px -3px 10px 3px #bebebe,3px 3px 10px 3px #b3b3b3; */
  border-radius: 2px !important;
  border: black solid 1px !important;
  overflow: hidden;
  align-self: center;
}

.productCard.ant-card-bordered {
  border: none !important;
}

.productCard.landscape {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.productCard.portrait {
  display: flex;
  align-items: center;
}

.productCard.portrait img {
  margin-bottom: 0px;
  margin-bottom: 0px;
  width: 80%;
  max-height: 240px;
}

.productCard.portrait .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  margin-bottom: 50px;
  height: 100%;
}

.productCard .ant-card-body .card-title:hover {
  text-decoration: underline;
}

.productCard.portrait .ant-card-body ul {
  margin-right: 5px;
  max-width: 170px;
}

.productCard.landscape img {
  max-height: 175px;
}

.productCard.landscape .ant-card-body {
  padding-top: 10px;
}

.card-divider {
  width: 100%;
  height: 5px;
  background-color: #a1c193;
}

.productCard .ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #f0f0f0;
  position: absolute;
  bottom: -5px;
  width: 100%;
  border-radius: 0 0px 10px 10px;
  background: transparent;
}

.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0 !important;
}

#rentPageContainer .ant-menu-inline .ant-menu-vertical .ant-menu-vertical-left {
  border: none !important;
}

.productCard .ant-card-actions li {
  height: 100%;
  margin: 0;
  padding: 12px 0;
  background-color: #f7f7f7;
}

.productCard .ant-card-body ul {
  padding: 3px;
  position: absolute;
  bottom: 34px;
}

.product-card-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  top: 45px;
  /* max-width: 300px;
  max-height: 200px; */
  height: 165px;
  width: 165px;
  margin: auto;
}

.product-card-image {
  /* max-height: 200px !important; */
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.productCard .ant-card-actions > *:hover {
  background-color: #bfd7b9;
  /* color: white !important; */
}

.productCard .ant-card-actions > * span:hover,
svg:hover {
  color: rgb(85, 85, 85) !important;
}

.productSearch {
  position: absolute;
  top: 0;
  left: 30%;
  width: 55% !important;
  margin-top: 10px !important;
}

.info-tag-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 15px;
  left: 35px;
  padding: 5px;
  z-index: 3;
}

.region-card {
  background-color: #f1f2f5;
  padding: 5px;
  border-radius: 2px;
  color: var(--black);
  cursor: pointer;
  margin-right: 5px;
}

.searchTerm {
  background-color: #f1f2f5;
  padding: 5px;
  border-radius: 2px;
  color: var(--black);
}

.searchTerm button {
  background: none;
  border: none;
  transition: 0.5s ease;
}

.searchTerm button:hover {
  background: lightgrey;
  color: red;
  transition: 0.5s ease;
  cursor: default !important;
}

.detailContentContainer {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px 0;
}

.details-title {
  text-align: left !important;
  margin-bottom: 0;
}

.posted-by {
  margin-bottom: 0.9rem;
  color: rgb(16, 102, 18);
  margin-left: 0.1rem;
  transition: 0.5s ease;
}

.posted-by:hover {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(17, 17, 185);
  transition: 0.5s ease;
}

@media (min-width: 715px) {
  .product-card-image-container {
    width: 190px;
    height: 190px;
  }

  .categoryMenu .individual-category:hover:not(.ant-menu-item-selected) {
    color: white !important;
  }
}

@media (min-width: 716px) {
  .categoryMenu {
    height: 85vh;
  }
}

@media (max-width: 850px) {
  .details-title {
    text-align: center !important;
  }

  .posted-by {
    text-align: center !important;
  }

  .detailContentContainer {
    flex-direction: column;
    width: 100%;
    justify-content: center !important;
  }

  .detailContentContainer > ul {
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }

  /* .detailsImage{
    width: 100% !important;
    max-width: 300px !important;
    border-radius: 4px;
    align-items: center;
  } */

  .detailsItem {
    padding: 10px !important;
    padding-top: 40px !important;
  }
}

.detailsItem {
  list-style-type: none;
  font-size: 1rem;
  size: 1.5rem;
  height: 100%;
  max-height: 1000px;
  width: 90%;
  max-width: 1000px;
  min-width: 350px;
  margin-top: 4%;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  background-color: #a1c193;
  box-shadow: 2px 1px 5px 3px lightgray;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--g2gDark);
  position: relative;
}

.detailsItem h1 {
  text-align: center;
}

.detailsFrame {
  width: 100%;
  height: 400px;
  border: none !important;
}

.detailsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  width: 95%;
  height: 100%;
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;
}

.product_detail_back_btn {
  position: absolute !important;
  left: 10px;
  top: 10px;
}

#approximate-location-message {
  width: 100%;
  text-align: center;
}

.gear-bag-image {
  width: 21px !important;
  height: 21px !important;
  box-shadow: none !important;
  margin-top: -3px !important;
  margin-right: 0px !important;
}

.search-page-footer {
  margin: 35px auto !important;
  bottom: 0;
  text-align: center;
}
