.productNavbar button {
  background-color: #659d32;
  border-color: #b6bd75;
}
.productNavbar button:hover {
  background-color: #6ead32;
  border-color: #bebd77;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.searchBarCap {
  width: 50px;
  background-color: white;
}
.leftCap {
  border-radius: 50% 0% 0% 50%;
}

.rightCap {
  border-radius: 0% 50% 50% 0%;
}
.productSearchLeftCap {
  border-radius: 50% 0% 0% 50%;
  border-left: gray 1px solid;
  border-top: gray 1px solid;
  border-bottom: gray 1px solid;
}

.productSearchRightCap {
  border-radius: 0% 50% 50% 0%;
  border-right: gray 1px solid;
  border-top: gray 1px solid;
  border-bottom: gray 1px solid;
}

.searchBarMain,
.productSearchBar {
  padding-left: 20px;
  border-radius: 50px;
}

.searchBarMain {
  height: 70px;
  border: none;
  width: 50vw;
  max-width: 850px;
  outline: none;
}

.productSearchBar {
  height: 50px;
  border: solid 1px gray;
  width: 300px;
  min-width: 100px;
  outline: none;
  font-size: 16px;
}

/* HOME PAGE SEARCH BAR PLACEHOLDER STYLING - IN CASE NEEDED - DELETE #s to apply effects*/

#mainSearchBar::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Montserrat', sans-serif;
  font-weight: lighter;
  font-size: 1.3rem;
}
#mainSearchBar::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
}
#mainSearchBar:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
}
#mainSearchBar:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
}

@media only screen and (max-width: 600px) {
  .productSearchBar,
  .searchBarMain {
    font-size: 16px;
  }

  .searchBarMain {
    width: 80vw;
  }

  .productSearchBar {
    width: 60vw;
  }

  #navContainer {
    width: 100vw;
  }
}
