.clip-third {
  clip-path: polygon(40% 0%, 40% 100%, 0 100%, 0 0);
}
.clip-half {
  clip-path: polygon(53% 0%, 53% 100%, 0 100%, 0 0);
}
.clip-twothirds {
  clip-path: polygon(64% 0%, 64% 100%, 0 100%, 0 0);
}
.clip-almostfull {
  clip-path: polygon(70% 0%, 70% 100%, 0 100%, 0 0);
}

/* .disabled svg:hover {
  color: black !important;
} */
