:root {
  --g2gDark: #5a7949;
  /* --g2gDark: #537558; old dark*/
  --g2gLight: #c2ee76;
  --g2gMedium: #7bd71f;
  --g2gLight2: rgb(187, 206, 190);
  --g2gLightYellow: rgb(224, 227, 210);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
