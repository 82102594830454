#previous-messages-container {
  height: 70vh;
  min-height: 400px;
  max-height: 800px;
  width: 70vw;
  max-width: 1000px;
  min-width: 350px;
  background-color: white;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

#previous-messages-container > * {
  text-align: right;
  padding: 5px;
  margin: 10px;
}

#message-input-form {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* Styling main messaging container */

#main-messages-container {
  margin: auto;
  width: 83vw;
  position: relative;
  border-right: 2px solid #eee;
  min-height: 420px;
  height: 86vh;
}
#message-right-container {
  margin-left: 300px;
  padding-top: 25px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #ececec;
  padding-bottom: 12px;
  height: 100%;
}
#messages-container {
  height: 90%;
  width: 100%;
  /* border: 1px solid black; */
  background-color: #f7f7f7;
  display: flex;
  overflow-y: scroll;
  flex-direction: column-reverse;
  padding: 15px 30px;
  max-width: 820px;
}
#messages-container > :first-child { margin-top: auto } 

.message-temp-inner-container {
  display: flex;
  flex-direction: column-reverse;
}

.temporary-rental-instructions-container {
  margin-bottom: 20px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgray;
}

.temporary-rental-instructions-container > * {
  text-align: left;
}

.temporary-rental-instructions-container > ol {
  padding-left: 20px;
  list-style-type: disc;
}

.indv-message {
  width: 60%;
  width: 40%;
  background-color: #b3e39d;
  border-radius: 8px;
  padding: 15px;
  min-width: 265px;
  text-align: left;
}

.my-message {
  margin-left: 50%;
  width: 40%;
  background-color: #92d7e8;
  border-radius: 8px;
  padding: 15px;
}
.other-message-name:hover,
.my-message-name:hover {
  text-decoration: underline;
  cursor: pointer;
}
.member-channel-icon {
  transition: 0.2s ease;
}
.member-channel-icon:hover {
  box-shadow: 0 0 5px 1px gray;
  transition: 0.5s ease-out;
}
/* Stying message input */

#message-input-container {
  width: 90%;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
}

#message-input-container input {
  margin-left: 5px;
}

.message-input-and-send {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

#message-input {
  min-width: 270px;
  max-width: 700px;
  resize: none;
}

#submit {
  height: 54px;
}

.stripe-and-email {
  display: flex;
  align-items: center;
  height: 50px;
}

.stripe-and-email > * {
  margin: 40px;
}

/* Styling for Channels/MessageGroup Container */

#channels-mg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: rgb(196, 196, 196);
  /* border-right: 1px solid rgb(192, 184, 139); */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}
#channels-mg-container h2 {
  padding: 30px;
  width: 300px;
  background-color: lightgray;
  text-align: center;
}
.indv-channel-container {
  width: 100%;
  height: 80px;
  min-height: 75px;
  border-bottom: 1px solid rgb(244, 244, 244);
  background-color: rgb(178, 223, 165);
  cursor: pointer;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  align-content: center;
}
.indv-channel-container div {
  display: flex;
  flex-direction: column;
}
.indv-channel-container div div {
  display: flex;
  flex-direction: row;
}
.indv-channel-container div p {
  text-align: center;
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.indv-channel-container:hover {
  background-color: rgb(159, 211, 124);
}
.indv-channel-container.selected-channel {
  background-color: rgb(120, 202, 99);
}

.message-back-button-container {
  width: 100%;
  display: flex;
  justify-content: left;
  padding: 15px;
}

.message-back-button {
  display: block;
  cursor: pointer;
  width: fit-content;
  height: 100%;
}

.message-back-button:hover {
  color: rgb(104, 104, 104);
}

#more-gear-channels:hover {
  color: #d5d5d5;
}

.mg-container-back-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

@media (max-width: 450px) {
  .mg-container-title {
    margin-left: 30px !important;
  }
}

/* quick message feature */

.quick-message-modal .ant-modal-title {
  font-size: 20px !important;
}

.quick-message-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.quick-message-product-display {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}

.quick-message-image-container {
  height: 150px;
  width: 150px;
  min-width: 150px;
  margin-right: 20px;
}

.quick-message-product-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.quick-message-product-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
}

.quick-message-textarea {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 154px;
  resize: none;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.quick-message-textarea:focus {
  outline: none !important;
}

.quick-message-predefined-messages {
  display: flex;
  width: 100%;
}

.quick-message-predefined-message {
  border: 1px solid lightgray;
  border-radius: 8px;
  height: 34px;
  margin-bottom: 10px;
  font-size: 16px;
}

.quick-message-view-messages-button-container {
  width: 100%;
}

.quick-message-view-messages-button {
  background-color: rgb(120, 202, 99);
  font-size: 16px;
  border: 1px solid gray;
}

.quick-message-modal .ant-btn-primary {
  background-color: #1890ff !important;
  color: white !important;
  padding: 4px 15px !important;
}