.profile-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  /* align-self: center; */
  border-radius: 50%;
  overflow: hidden;
  width: 300px;
  height: 300px;
  background-color: black;
  /* margin: 13%; */
}
.profile-image-container-product-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  /* align-self: center; */
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 100px;
  background-color: black;
  /* margin: 13%; */
}

.profileImage {
  display: flex;
  width: 330px !important;
}
.profileImage-product-details {
  display: flex;
  width: 130px !important;
}

.prod-det-member-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}

.rent-more-from {
  margin-left: 10px;
  font-family: var(--subheadline);
  font-size: xx-large;
}
.rent-more-from-container {
  margin: 20px 0px;
}

.rent-more-hyperlink {
  /* color: var(--color3) !important; */
}
