#g-recaptcha {
  display: flex;
  justify-content: center;
}

#loginForm {
  position: relative !important;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 4px lightgrey;
  min-width: 360px;
  max-width: 500px;
  min-height: 30vh;
  background-color: #ececec;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  padding: 30px;
  text-align: center;
  background-color: var(--g2gLight2);
}
#loginForm h1 {
  text-decoration: underline;
}
#loginForm .form {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
#loginForm fieldset {
  padding: 10px;
  width: 70%;
  border: none;
  margin: auto;
}

.login-box-main.login-box {
  min-width: 375px;
  max-width: 500px;
  padding: 20px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  box-shadow: 0 15px 25px var(--bg-color);
  border-radius: 10px;
  text-align: center;
}

.login-box-main .main-logo {
  margin: auto;
  width: 210px;
}

.register-box {
  background-color: var(--bg-color) !important;
  box-shadow: 0 15px 25px var(--bg-color) !important;
  border-radius: 10px;
  text-align: center;
  margin-top: 40px !important;
}
.register-box span {
  color: lightgrey !important;
}
.register-box h6 {
  color: lightgrey !important;
}

.terms-container {
  height: 400px;
  overflow: scroll;
  border: 1px solid rgb(228, 228, 228);
  padding: 10px;
}

.referred-by-text {
  color: white;
}

.custom-alert .login-box > *,
.custom-alert .login-box form > * {
  margin: 10px;
}
.custom-alert .login-box form {
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
