.refer-a-friend-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.refer-a-friend-content-textfield {
  width: 300px;
  margin: 10px 0 !important;
}

.refer-a-friend-content-invite-button {
  width: 170px;
}

.refer-a-friend-status-message {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-size: 18px;
}
