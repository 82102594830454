.gear-loader {
  animation: spinnerAnimation 1.3s infinite cubic-bezier(0.53, 0.21, 0.29, 0.67);
  width: 226.5px;
  height: 239.5px;
  margin: 50px auto;
  transform: scaleX(-1);
}
.gear-loader-container {
  width: 100%;
  display: flex;
}

@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
