.money-flex {
  display: flex;
  align-items: baseline;
}

.dollar {
  font-weight: bold;
  color: black;
  font-size: 80%;
}

.price {
  position: relative;
  color: olivedrab;
  font-size: 22px;
  font-weight: 600;
  padding: 0 4px;
  bottom: -2px;
}

.slash {
  font-size: 14px;
  font-weight: normal;
  color: #0b0e0b;
  padding: 0 2px 0 0;
}

.duration {
  color: #0b0e0b;
  font-weight: 600;
  font-size: 14px;
}
