.footer-icon {
  font-size: 40px !important;
  margin-right: 20px;
}

#footer {
  position: relative;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  z-index: 1001;
}

.footer-text {
  display: flex;
  justify-content: space-around;
}

.footer-text > * {
  margin: 15px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerLinks > ul {
  margin: 0;
  padding: 0;
}

.footer-mobile-links {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 20px;
}
