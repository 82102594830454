.rentRequestFlexColumn {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  max-height: 90%;
}

.rentRequestFormContainer {
  border: 0.5px solid black;
  border-radius: 4px;
  flex-flow: column;
  padding: 8px;
  background-color: white;
  min-width: 80%;
}

.rentRequestEmailContainer {
  padding: 8px;
}

.rentRequestLabel {
  padding: 0 4px;
}

.requestButtonContainer {
  display: flex;
  justify-content: center;
  padding: 8px;
}

.rentRequestRobotContainer {
  padding: 4px;
}

.rentRequestSubmittedContainer {
  border: 0.5px solid black;
  border-radius: 4px;
  flex-flow: column;
  padding: 8px;
  background-color: white;
  max-width: 80%;
  margin: auto;
}

#rentRequestAddButton {
  background-color: #28afdb;
  color: white;
  font-size: 10px;
  margin: 10px;
}

#rentRequestRemoveButton {
  background-color: #c33132;
  color: white;
  font-size: 10px;
  margin: 10px;
}

#rentRequestPageContainer {
  position: relative;
  text-align: center;
  margin: 0 auto 0 auto;
  width: 100%;
  min-height: 40vh;
  height: 90vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow: scroll;
}

.singleRentRequest {
  padding: 4;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .singleRentRequest {
    flex-direction: column;
  }
}
