/* Slideshow container */
.media {
  width: 100%;
  max-height: 240px;
  margin: 0 auto;
}

.media-large {
  width: 100%;
  width: 100% !important;
  max-width: 300px !important;
  border-radius: 4px;
  align-items: center;
}

.mySlides {
  display: none;
}

.slideIndicator {
  text-align: center;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 7px;
  width: 7px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot:hover {
  background-color: var(--g2gDark);
}

.dot-detail {
  background-color: #f2f2f2;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.active {
  background-color: var(--g2gDark);
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
