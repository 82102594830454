.shoppingCart {
  text-align: center;
  /* width: 80%;
    margin: 10px auto 40px auto;
    background-color: rgb(224, 227, 210);
    padding: 20px; */
}

.cartItemsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
  width: 98%;
  margin: 10px auto 40px auto;
  padding: 20px;
}
.cartItemsContainer .productCard {
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  height: 500px;
}

.shopping-cart-title-container {
  /* margin-top: 40px; */
  align-self: center;
  text-align: center;
  padding: 20px;
  /* background-color: rgb(224, 227, 210); */
  background-color: var(--black);
  color: var(--white);
}

.shopping-cart-heading {
  margin: '10px auto 15px auto';
  color: var(--white);
  font-family: var(--headline);
}

.cartItem {
  width: 300px;
  margin: 2%;
  padding: 15px;
  border: 1px solid lightgray;
  box-shadow: 2px 2px 2px lightgray;
}
.cartItemImage {
  width: 100%;
  height: 200px;
}
.cartItemImage img {
  width: 100%;
  height: 200px;
}
.shoppingCart button {
  margin: 10px !important;
}
.orderListItem {
  max-width: 800px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  background-color: lightblue;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  border: 1px solid lightgray;
  margin-top: 5px;
}
.orderListItem:hover {
  background-color: aqua;
}
.orderListItem div {
  display: flex;
  flex-direction: row;
  align-self: center;
  padding-top: 10px !important;
}
.orderListItem div > * {
  display: flex;
  flex-direction: row;
  align-self: center;
  padding: 0px 10px;
}
