@import '~react-image-gallery/styles/css/image-gallery.css';

.product-details-title {
  font-family: var(--headlineFont);
  font-size: 2.2rem;
}

.product-details-column {
  display: flex;
  flex-direction: column;
}

.details-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px;
}

.detailsImage {
  width: 100%;
  border-radius: 4px;
  /* box-shadow: -2px -2px 10px 0 #7d7d7d,2px 2px 12px 0 #6c6c6c; */
}

.margin-20-0 {
  margin: 20px 0;
}

.details-member-image-container {
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  overflow: hidden;
  width: 300px;
  height: 300px;
  background-color: black;
  /* margin: 13%; */
}

#detail-page-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin: 10px 30px;
  max-width: 90%;
}

.details-message-button {
  background-color: rgb(136, 205, 57);
  width: 300px;
  padding: 15px;
  border-radius: 100px;
  font-size: large;
  text-align: center;
  margin: 20px 0;
}

.details-message-button:hover {
  cursor: pointer;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

#productDetails-mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
}

.productDetails-mainContainer > div {
  margin: 20px 0;
}

.productDetails-imageAndDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
}

.productDetails-imageAndDescription > div {
  margin: 30px 0;
}

#detail-map-container {
  display: flex;
  justify-content: center;
}

#detail-map-container > div {
  width: 80vw !important;
}

.productDetails-imageContainer > img {
  object-fit: cover;
}

.productDetails-titleDescriptionInstructions {
  width: 340px;
  position: relative;
}

.productDetails-details {
  display: flex;
  flex-direction: column;
  position: relative;
}

.edit-button {
  position: absolute !important;
  top: -25px;
  right: 5px;
}

.productDetails-rentMoreFrom {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 10px;
}

.rent-more-from {
  font-size: 24px !important;
  margin: 0 0 0 10px;
  width: 224px;
}

.image-gallery-image {
  height: 340px !important;
  width: 340px !important;
}

.image-gallery-bullet {
  border-color: #49b4f2 !important;
}

.active {
  background-color: #49b4f2 !important;
  border-color: #49b4f2 !important;
}

.productDetails-imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 340px;
}

.image-gallery-svg {
  width: 24px !important;
  height: 48px !important;
}

.no-reviews-yet {
  text-align: center;
}

.productDetails-backButton-container {
  margin: 25px 0 !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.productDetails-backButton {
  font-size: 24px;
  margin-left: 22px;
}

.productDetails-imageContainer {
  margin-top: 0 !important;
}

@media only screen and (min-width: 600px) {
  .productDetails-imageContainer {
    height: auto;
  }

  .productDetails-backButton-container {
    display: none;
  }

  .productDetails-imageContainer {
    margin-top: 10px !important;
  }
}

@media only screen and (min-width: 650px) {
  .productDetails-imageContainer {
    width: 100%;
    height: auto;
  }
  .image-gallery-image {
    width: 500px !important;
    height: 500px !important;
  }

  .productDetails-titleDescriptionInstructions {
    width: 500px;
  }

  #detail-map-container > div {
    width: 60vw !important;
  }

  .image-gallery-thumbnails-container {
    text-align: left !important;
  }
}

@media only screen and (min-width: 1100px) {
  .productDetails-imageAndDescription {
    flex-direction: row;
  }

  .productDetails-imageContainer {
    width: 500px;
    margin-right: 80px !important;
  }

  .product-details-title {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 1500px) {
  #detail-map-container > div {
    width: 50vw !important;
  }
}
