.headline-font {
  font-family: 'Poppins', sans-serif;
}

.subHeadline-font {
  font-family: 'Sanchez', serif;
}

.body-font {
  font-family: 'Montserrat', sans-serif;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: left;
}

#profile-section-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10vh auto;
  width: 99vw;
  max-width: 800px;
  justify-content: center;
}

#profile-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 55px;
  /* padding: 100px; */
}

/* .profileContainer .detailsItem{
    display: flex;
    flex-direction: row;
    background-color: #a1c193 !important;
    max-width: 650px;
}
.profileContainer .detailsItem .detailsImage{
    max-width: 275px;
    min-width: 200px;
    width: 40% !important;
} */
.yourShopContainer {
  margin-top: 40px;
  background-color: var(--gray);
}

.lenderGearContainer {
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: 98%;
  /* padding: 20px; */
  box-shadow: 2px 2px gainsboro;
  border: none;
}
.other-member-card h3:hover {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s ease;
}

.paymentMethodContainer div {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.cardContainer {
  position: relative;
  width: 350px;
  height: 225px;
  background-image: linear-gradient(to bottom right, #0166b4, #5b9793);
  box-shadow: 2px 2px 2px 2px lightgray;
  color: #b7b7b7;
  border-radius: 7px;
  margin: 20px;
}
.cardContainer > * {
  color: #b7b7b7;
  text-shadow: 1px 1px 2px black;
}
.cardContainer .deleteCard {
  text-shadow: none !important;
}
.cardChipImg {
  position: absolute;
  top: 100px;
  right: 40px;
  width: 10%;
}

.cardType {
  position: absolute;
  top: 20px;
  left: 30px;
}
.cardAccount {
  position: absolute;
  top: 108px;
  left: 90px;
}

.cardExp {
  position: absolute;
  top: 160px;
  right: 40px;
}
.cardHolder {
  position: absolute;
  top: 160px;
  left: 40px;
}
.deleteCard {
  position: absolute;
  top: 20px;
  right: 10px;
}

.delete-account-button {
  border-color: #f03c13 !important;
  color: black !important;
}

.delete-account-button:hover {
  background-color: #f03c13 !important;
  border-color: #f03c13 !important;
  color: black !important;
}

.delete-account-button:focus {
  color: black !important;
}
