table {
  border-collapse: collapse;
  width: 80%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
th {
  color: var(--white);
  background-color: var(--black);
}
th td {
  font-family: var(--headline);
}
td {
  font-family: var(--subheadline);
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.gear-request-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.gear-request-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.gear-request-container > * {
  margin-bottom: 30px;
}

.posted-by-link {
  color: var(--blue2);
}

.posted-by-link:hover {
  cursor: pointer;
}

.request-box {
  min-width: 375px;
  max-width: 500px;
  padding: 20px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  box-shadow: 0 15px 25px var(--bg-color);
  border-radius: 10px;
  text-align: center;
}

.request-box > * {
  margin-top: 20px;
}
.request-box img {
  margin-bottom: 30px;
  margin-top: 0px;
}
.request-box label {
  color: white !important;
}

.gear-request-title {
  text-align: center;
  text-decoration: underline;
  margin-top: 30px;
  color: #343434;
  font-weight: normal;
  font-family: 'Ultra', sans-serif;
  font-size: 36px;
  line-height: 42px;
}

.gear-request-box {
  color: lightgray;
  min-width: 375px;
  max-width: 500px;
  padding: 20px;
  top: 45%;
  left: 50%;
  background-color: #659d32;
  box-shadow: 0 15px 25px var(--bg-color);
  border-radius: 10px;
  text-align: center;
  margin: 25px;
}
.gear-request-box h2 {
  text-decoration: underline;
}

.request-cards-container {
  justify-content: center;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  left: 0;
  height: 100%;
  min-height: 70vh;
  align-content: flex-start;
  padding-bottom: 75px;
  margin: 25px auto 80px auto;
}

.product-request-searchbar {
  /* margin: auto; */
  width: 50%;
  max-width: 400px;
  min-width: 300px;
  /* background-color: #659d32 !important; */
}

.product-request-searchbar button {
  background-color: var(--black);
  border-color: #b6bd75;
}

.product-request-searchbar button:hover {
  background-color: #6ead32;
  border-color: #bebd77;
}

.request-form-button {
  /* margin: auto; */
  display: flex;
  /* margin-top: 50px; */
  /* margin-bottom: 50px; */
  background-color: var(--gray);
  /* border: 2px solid tan; */
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  color: black !important;
  border-radius: 5px;
}

.request-form-button:hover {
  background-color: lightgray;
}

.no-results-container {
  margin-bottom: 150px;
}

.gear-request-container h3,
.gear-request-container h1 {
  text-align: center;
  width: 50%;
  min-width: 350px;
}
