#location-cover {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 3;
}

.location-selection {
  cursor: pointer;
  padding: 5px;
}

.location-selection:hover {
  background-color: rgb(193, 193, 193);
}
